import { createAuth0 } from '@auth0/auth0-vue';

const namespace = 'https://id.stjude.cloud';

export const auth0 = createAuth0({
  // eslint-disable-next-line no-undef
  domain: config.VUE_APP_AUTH0_DOMAIN || process.env.VUE_APP_AUTH0_DOMAIN,
  // eslint-disable-next-line no-undef
  clientId: config.VUE_APP_AUTH0_CLIENT_ID || process.env.VUE_APP_AUTH0_CLIENT_ID,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  // eslint-disable-next-line no-undef
  cookieDomain: config.VUE_APP_AUTH0_COOKIE_DOMAIN || process.env.VUE_APP_COOKIE_DOMAIN,
  authorizationParams: {
    redirect_uri: window.location.origin,
    // eslint-disable-next-line no-undef
    audience: config.VUE_APP_AUTH0_AUDIENCE || process.env.VUE_APP_AUTH0_AUDIENCE,
  }
});

export const getAccessTokenSilently = async () => {
  return await auth0.getAccessTokenSilently();
}

export const getIsAuthenticated = () => {
  return auth0.isAuthenticated.value;
}

export const loginWithRedirect = async () => {
  await auth0.loginWithRedirect({
    prompt: "login",
    appState: {
      target: window.location.pathname,
    },
  });
}

export const userRoles = () => {
  return (auth0.user.value && auth0.user.value[`${namespace}/roles`]) || [];
}

export const hasAnyRole = (roles) => {
  return roles.some((role) => userRoles().includes(role));
}
