<template>
  <footer>
    <div class="max-w-1140px container">
      <div class="row">
        <div class="col-6 col-lg-3 col-md-4 offset-xl-0 offset-lg-5 offset-md-4 offset-3 logo-container">
          <a
            class="navbar-brand"
            href="https://stjude.cloud"
          >
            <span class="logo" />
          </a>
          <a
            class="sjc-title"
            href="https://stjude.cloud"
          >St. Jude Cloud</a>
        </div>
        <div class="col-md-3 col-6 offset-xl-0 offset-md-2 footer-about">
          <h6 class="mb-2">
            About
          </h6>
          <ul>
            <li
              v-for="link in aboutLinks"
              :key="link.title"
            >
              <a
                class="hover:underline"
                :href="link.url"
                :title="link.title"
              >{{ link.text }}</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3 col-6 offset-xl-0 offset-md-2 footer-apps">
          <h6 class="mb-2">
            Apps
          </h6>
          <ul>
            <li
              v-for="app in apps"
              :key="app.title"
            >
              <a
                class="hover:underline"
                :href="app.url"
                :title="app.title"
              >{{ app.title }}</a>
            </li>
          </ul>
        </div>
        <div class="col-xl-3 col-lg-6 col-8 offset-xl-0 offset-lg-3 offset-2 footer-social">
          <h6 class="mb-2">
            Follow Us
          </h6>
          <div class="d-flex justify-content-between">
            <a
              v-for="social in socials"
              :key="social.title"
              target="_blank"
              rel="noopener noreferrer"
              class="bg-content-dark-blue rounded-3xl h-[40px] w-[40px] hover:bg-blue-primary text-center text-white"
              :href="social.url"
              :title="social.title"
            >
              <font-awesome-icon
                :icon="['fab', social.title.toLowerCase()]"
                class="mt-[12px]"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="sub-footer container-fluid">
      <div class="max-w-1140px container">
        <div class="row">
          <div class="col-xl-6 col-lg-12 order-last whitespace-nowrap">
            <ul>
              <li
                v-for="(link, index) in links"
                :key="link.title"
                class="inline-block"
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  class="hover:underline"
                  :href="link.url"
                  :title="link.title"
                >{{ link.text }}</a>
                <span
                  v-if="index !== links.length - 1"
                  class="px-1"
                >&#8226;</span>
              </li>
            </ul>
          </div>
          <div class="col-xl-6 col-md-12 col-8 offset-md-0 offset-2">
            <p>
              &copy; Copyright {{ new Date().getFullYear() }}
              <a
                class="hover:underline"
                href="https://www.stjude.org"
                title="St. Jude Children's Research Hospital"
                target="_blank"
              >
                St. Jude Children's Research Hospital
              </a>, a not-for-profit, section 501(c)(3).
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
const aboutLinks = [
  {
    url: 'https://stjude.cloud',
    text: 'Home',
    title: 'St. Jude Cloud Home',
  },
  {
    url: 'https://stjude.cloud/contact',
    text: 'Contact',
    title: 'Contact',
  },
  {
    url: 'https://talent.stjude.org/data-sciences/jobs',
    text: 'Careers',
    title: 'Careers',
  },
  {
    url: 'https://stjude.cloud/privacy-policy',
    text: 'Privacy Policy',
    title: 'Privacy Policy',
  },
  {
    url: 'https://stjude.cloud/terms-of-use',
    text: 'Terms of Use',
    title: 'Terms of Use',
  },
];

const apps = [
  {
    url: 'https://platform.stjude.cloud',
    title: 'Genomics Platform',
  },
  {
    url: 'https://models.stjude.cloud',
    title: 'Model Systems',
  },
  {
    url: 'https://pecan.stjude.cloud',
    title: 'Pediatric Cancer Portal (PeCan)',
  },
  {
    url: 'https://viz.stjude.cloud',
    title: 'Visualization Community',
  },
];

const links = [
  {
    url: 'https://www.stjude.org/legal/u-s-privacy-policy-statement.html',
    text: 'Privacy',
    title: 'U.S. Privacy Policy Statement',
  },
  {
    url: 'https://www.stjude.org/legal.html',
    text: 'Disclaimer / Registrations / Copyright',
    title: 'Disclaimer / Registrations / Copyright Statement',
  },
  {
    url: 'https://www.stjude.org/legal/linking-policy.html',
    text: 'Linking',
    title: 'Linking Policy',
  },
  {
    url: 'https://www.stjude.org/legal/notice-of-privacy-practices.html',
    text: 'Privacy (HIPAA)',
    title: 'Notice of Privacy Practices (HIPAA)',
  },
  {
    url: 'https://www.stjude.org/legal/notice-of-non-discrimination.html',
    text: 'Non-Discrimination',
    title: 'Notice of Non-Discrimination',
  },
];

const socials = [
  {
    url: 'https://www.facebook.com/stjuderesearch',
    title: 'Facebook',
  },
  {
    url: 'https://twitter.com/StJudeResearch',
    title: 'Twitter',
  },
  {
    url: 'https://www.instagram.com/stjuderesearch/',
    title: 'Instagram',
  },
  {
    url: 'https://www.linkedin.com/company/st-jude-childrens-research-hospital/',
    title: 'LinkedIn',
  },
  {
    url: 'https://www.youtube.com/user/MyStJude',
    title: 'YouTube',
  },
];
</script>
